import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { uploadShowThumbnail } from '@/actions/upload-show-thumbnail'
import {
  mappingFromShippingCategory,
  mappingToShippingCategory,
} from '@/modules/shipping-category/mapping-shipping-category'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'

import useParentCategories from '../CreateOrEditProduct/useParentCategories'
import Alert from '../ui/Alert/Alert'
import Button from '../ui/Button/Button'
import Dialog from '../ui/Dialog/Dialog'
import { notificationDanger, notificationSuccess } from '../ui/Notification/Notification'

import { Category } from './components/Category'
import { Details } from './components/Details'
import { ImageAndVideo } from './components/ImageAndVideo'
import { VideoUploadedStatus } from './components/VideoUploader/VideoUploader.container'
import {
  useCreateShowMutation,
  useUpdatePreShowTeaserVideoUrlMutation,
  useUpdateShowMutation,
} from './operations.generated'

import type { VideoPreShow } from './components/ImageAndVideo'
import type { ShowShippingOption } from '@/types'
import type { ShowByIdFragment } from '@/views/Show/operations.generated'
import type { ShowFormStep } from '@/views/Show/Show'
import type { UploadFile } from 'antd'

import './CreateShow.scss'
interface CreateShowProps {
  onClose: () => void
  show?: ShowByIdFragment
  stepForm?: ShowFormStep
  isShop?: boolean
  startAt?: string
  category?: number
}

type CreateShowFormData = {
  category: string
  subCategory: string
  image: string | UploadFile
  video: VideoPreShow
  name: string
  note?: string
  language: string
  shipping: string
  reuse_shipping: boolean
  target: number
  preAuth?: number
  date?: string
  time?: string
}

export const CreateShow = (props: CreateShowProps) => {
  const { t } = useTranslation()
  const { onClose, show, stepForm, isShop, startAt, category } = props
  const isUpdate = !!show

  const { parentCategories } = useParentCategories()
  console.log(parentCategories)
  const flattenCategories = parentCategories
    ? parentCategories.map((item) => {
        return {
          id: item.id,
          children: item.categories && item.categories.length > 0 ? item.categories.map((child) => child.id) : [],
        }
      })
    : []

  const defaultCategory = category ? `Category|${category}` : undefined

  // return the id of flatten category if defaultcategory is in his children object
  const defaultParentCategory = defaultCategory
    ? flattenCategories.find((item) => item.children.includes(defaultCategory))?.id
    : undefined
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(stepForm || 1)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const [createShowMutation] = useCreateShowMutation()
  const [updateShowMutation] = useUpdateShowMutation()
  const [updatePreShowTeaserVideoUrl] = useUpdatePreShowTeaserVideoUrlMutation()

  const [formData, setFormData] = useState<CreateShowFormData>({
    category:
      show && show.category.parentCategory.id
        ? show.category.parentCategory.id
        : defaultParentCategory
          ? defaultParentCategory
          : '',
    subCategory: show && show.category.id ? show.category.id : defaultCategory ? defaultCategory : '',
    image: show?.thumbnailWebPUrl || '',
    video: {
      name: '',
      size: 0,
      status: show?.preShowTeaserVideoInfo?.mp4HdFileInfo?.url
        ? VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED
        : VideoUploadedStatus.NO_VIDEO,
      url: show?.preShowTeaserVideoInfo?.mp4HdFileInfo?.url || '',
    },
    name: show?.name || '',
    note: show?.note || '',
    language: show?.language.id || '',
    shipping: show?.shippingCategory ? mappingFromShippingCategory[show.shippingCategory] : '',
    reuse_shipping: show?.isReusingPaidShippingFeesFromPreviousShows || false,
    target: show?.gmvTargetAmount ? show.gmvTargetAmount / 100 : 1,
    preAuth: show?.paymentPreAuthorizationAmount ? show.paymentPreAuthorizationAmount / 100 : undefined,
    date: show?.startAt
      ? dayjs(show.startAt).format('YYYY-MM-DD')
      : isShop
        ? dayjs().add(89, 'D').format('YYYY-MM-DD')
        : startAt
          ? dayjs(startAt).format('YYYY-MM-DD')
          : undefined,
    time: show?.startAt
      ? dayjs(show.startAt).format('H:mm')
      : isShop
        ? '23:59'
        : startAt
          ? dayjs(startAt).format('H:mm')
          : undefined,
  })

  const handleNextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  const handlePreviousStep = useCallback(() => {
    setStep(step - 1)
  }, [step])

  const uploadThumbnailOnShow = async (showId: number, thumbnail: UploadFile<any>) => {
    const thumbnailUpload = await dispatch(
      uploadShowThumbnail({
        showId,
        thumbnail: thumbnail as unknown as File,
      })
    )

    if (thumbnailUpload.type.indexOf('rejected') !== -1) {
      notificationDanger(thumbnailUpload?.payload?.details)
    }
  }

  const handleTeaserVideoUpdate = async (showId: number, teaserVideo: VideoPreShow) => {
    if (!showId || !teaserVideo || teaserVideo.url === '') {
      return
    }

    if (teaserVideo.status !== VideoUploadedStatus.VIDEO_UPLOADED) {
      return
    }

    const { name, size } = teaserVideo

    await updatePreShowTeaserVideoUrl({
      variables: {
        input: {
          showId: `Show|${showId}`,
          uploadUrl: teaserVideo.url,
          originalFileInfo: {
            name: name,
            size: size,
          },
        },
      },
      onError(error) {
        notificationDanger(error.message)
      },
    })
  }

  const handleSubmit = useCallback(async () => {
    if (!formData.date || !formData.time || !dayjs(`${formData.date} ${formData.time}`).isValid()) {
      logger({
        level: LOGGER_LEVEL.WARN,
        message: 'Invalid date format on create / update show',
        meta: { formData, date: dayjs(`${formData.date} ${formData.time}`).toDate(), isUpdate },
      })
      setError(t('createShowDateRequired'))
      return
    }

    setError(null)
    setIsLoading(true)

    const input = {
      name: formData.name,
      note: formData.note,
      shippingCategory: mappingToShippingCategory[formData.shipping as ShowShippingOption],
      startAt: dayjs(`${formData.date} ${formData.time}`).toDate(),
      paymentPreAuthorizationAmount: formData.preAuth ? formData.preAuth * 100 : 0,
      gmvTargetAmount: formData.target * 100,
      isReusingPaidShippingFeesFromPreviousShows: formData.reuse_shipping,
      languageId: formData.language,
    }
    const img = formData.image as UploadFile
    const imgToUpload = img?.originFileObj as UploadFile

    const teaserVideo = formData.video

    if (!isUpdate) {
      await createShowMutation({
        variables: {
          input: {
            ...input,
            categoryId: parseInt(formData.subCategory.split('|')[1]),
          },
        },
        context: {
          noRetry: true,
        },
        onError: (error) => {
          setError(error.message)
          setIsLoading(false)
        },
        onCompleted: async (data) => {
          const showId = data.createShow.show.legacyId

          if (imgToUpload && showId) {
            await uploadThumbnailOnShow(showId, imgToUpload)
          }

          if (teaserVideo.url !== '') {
            await handleTeaserVideoUpdate(showId, teaserVideo)
          }

          onClose()
          notificationSuccess(
            t(isShop ? 'createShopModalDetailsSuccessMessage' : 'createShowModalDetailsSuccessMessage')
          )
          navigate(isShop ? '/shops/' + showId : '/shows/' + showId)
        },
      })
    } else {
      await updateShowMutation({
        variables: {
          input: {
            ...input,
            categoryId: formData.subCategory,
            showId: show.id,
          },
        },
        onError: (error) => {
          setError(error.message)
          setIsLoading(false)
        },
        onCompleted: async (data) => {
          const showId = data.updateShow.show.legacyId

          if (imgToUpload && showId) {
            await uploadThumbnailOnShow(showId, imgToUpload)
          }

          if (teaserVideo.url !== '' && teaserVideo.status !== VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED) {
            await handleTeaserVideoUpdate(showId, teaserVideo)
          }
          setIsLoading(false)
          onClose()

          notificationSuccess(
            t(isShop ? 'updateShopModalDetailsSuccessMessage' : 'updateShowModalDetailsSuccessMessage')
          )
        },
      })
    }
  }, [formData, createShowMutation, updatePreShowTeaserVideoUrl, onClose, t, history])

  const handleCategoryComplete = useCallback(
    (category: string, subCategory: string) => {
      setFormData({
        ...formData,
        category,
        subCategory,
      })
    },
    [formData]
  )

  const handleImageAndVideoChange = useCallback(
    (image: string | UploadFile, video: VideoPreShow) => {
      setFormData({
        ...formData,
        image,
        video,
      })
    },
    [formData]
  )

  const handleDetailsChange = useCallback(
    (
      name: string,
      language: string,
      shipping: string,
      reuse_shipping: boolean,
      target: number,
      date?: string,
      time?: string,
      note?: string,
      preAuth?: number
    ) => {
      setFormData({
        ...formData,
        name,
        note,
        language,
        shipping,
        reuse_shipping,
        target,
        preAuth,
        date,
        time,
      })
    },
    [formData]
  )

  return (
    <Dialog
      className="create-show"
      drawer={true}
      isOpen={true}
      modal={true}
      title={`${isUpdate ? (isShop ? t('updateShopTitle') : t('updateShowTitle')) : isShop ? t('createShopTitle') : t('createShowTitle')} (${step}/3)`}
      onClose={onClose}
    >
      <header className="create-show-header">
        <div className={`create-show-step ${step >= 1 ? 'active' : ''}`}></div>
        <div className={`create-show-step ${step >= 2 ? 'active' : ''}`}></div>
        <div className={`create-show-step ${step >= 3 ? 'active' : ''}`}></div>
      </header>
      <div className="create-show-form">
        {step === 1 && (
          <Category
            category={formData.category}
            isShop={isShop}
            subCategory={formData.subCategory}
            onComplete={handleCategoryComplete}
          />
        )}
        {step === 2 && (
          <ImageAndVideo
            image={formData.image}
            isUploading={isUploading}
            showId={show?.id}
            video={formData.video}
            onChange={handleImageAndVideoChange}
            onUploadChange={setIsUploading}
          />
        )}
        {step === 3 && (
          <Details
            category={formData.subCategory}
            date={formData.date}
            isShop={isShop}
            isUpdate={isUpdate}
            language={formData.language}
            name={formData.name}
            note={formData.note}
            preAuth={formData.preAuth}
            reuse_shipping={formData.reuse_shipping}
            shipping={formData.shipping}
            target={formData.target}
            time={formData.time}
            onChange={handleDetailsChange}
          />
        )}

        {error && <Alert type="danger">{error}</Alert>}
      </div>
      <footer className="create-show-buttons">
        {step !== 1 && (
          <Button
            className="create-show-button-previous secondary"
            label={t('commonPrevious')}
            onClick={handlePreviousStep}
          />
        )}
        {step !== 3 && (
          <Button
            className="create-show-button-next primary"
            label={t('commonNext')}
            disabled={
              (step === 1 && (formData.category === '' || formData.subCategory === '')) ||
              (step === 2 && formData.image === '') ||
              isUploading
            }
            onClick={handleNextStep}
          />
        )}
        {step === 3 && (
          <Button
            className="create-show-button-next primary"
            isLoading={isLoading}
            label={isUpdate ? t('commonUpdate') : isShop ? t('commonCreate') : t('commonSchedule')}
            disabled={
              formData.name === '' ||
              formData.language === '' ||
              formData.shipping === '' ||
              formData.target <= 0 ||
              formData.date === undefined ||
              formData.time === undefined
            }
            onClick={handleSubmit}
          />
        )}
      </footer>
    </Dialog>
  )
}
