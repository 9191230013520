import { useCallback, useEffect, useRef, useState } from 'react'

import Carousel from '@/components/Carousel'
import Dialog from '@/components/ui/Dialog/Dialog'

import {
  type CommercialEventApplicationCriteria,
  type FeaturedShowApplicationStatus,
  FeaturedShowApplicationType,
} from '../../../../network/graphql/types.generated'
import { useGetCommercialEventApplicationsQuery } from '../../operations.generated'

import CommercialEventCriteria from './components/commercialEventApplication/CommercialEventCriteria'
import FeaturedShowConcept from './components/featuredShowApplication/FeaturedShowConcept'
import FeaturedShowCriteria from './components/featuredShowApplication/FeaturedShowCriteria'
import ShowEventApplicationHeader from './components/ShowEventApplicationHeader'
import ShowEventApplicationSuccess from './components/ShowEventApplicationSuccess'
import ShowEventList from './components/ShowEventList'

import type { ShowEventApplicationType, CommercialEventApplications } from './types'

import './ShowEventApplication.scss'

type ShowEventApplicationProps = {
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  showId: string
  showGlobalParentCategoryId: string
  showCategorySlug: string
  commercialEventApplicationCriteria: CommercialEventApplicationCriteria
  featuredShowApplicationStatus: FeaturedShowApplicationStatus | null
}

const ShowEventApplication = (props: ShowEventApplicationProps) => {
  const {
    setIsOpen,
    isOpen,
    showId,
    showGlobalParentCategoryId,
    showCategorySlug,
    commercialEventApplicationCriteria,
    featuredShowApplicationStatus,
  } = props

  const [selectedSlide, setSelectedSlide] = useState<number>(0)
  const [featuredShowTargetInEuros, setFeaturedShowTargetInEuros] = useState<number>(0)
  const [showEventApplicationType, setShowEventApplicationType] = useState<ShowEventApplicationType | null>(null)
  const [featuredShowApplicationType, setFeaturedShowApplicationType] = useState<FeaturedShowApplicationType>(
    FeaturedShowApplicationType.Featured
  )
  const [commercialEventApplications, setCommercialEventApplications] = useState<CommercialEventApplications>(null)

  const carouselRef = useRef<any>(null)

  const { data } = useGetCommercialEventApplicationsQuery({
    variables: { showId },
    skip: !isOpen,
  })

  useEffect(() => {
    if (data?.node?.__typename === 'Show') {
      setCommercialEventApplications(data.node.commercialEventApplications ?? null)
    }
  }, [data])

  const handleNextSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
    setSelectedSlide((prev) => prev + 1)
  }, [])

  const handlePreviousSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.prev()
    }
    setSelectedSlide((prev) => {
      if (prev <= 1) {
        setShowEventApplicationType(null)
        return 0
      }
      return prev - 1
    })
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleSelectType = useCallback((application: ShowEventApplicationType) => {
    setShowEventApplicationType(application)
    setSelectedSlide(1)
    if (carouselRef.current) {
      carouselRef.current.goTo(1)
    }
  }, [])

  const renderSlides = useCallback(() => {
    const slides = [
      <ShowEventList
        key="preliminary"
        commercialEventApplications={commercialEventApplications}
        featuredShowApplicationStatus={featuredShowApplicationStatus}
        showId={showId}
        onSelectType={handleSelectType}
      />,
    ]

    if (showEventApplicationType?.type === 'featured') {
      slides.push(
        <FeaturedShowCriteria
          key="step1"
          featuredShowApplicationType={featuredShowApplicationType}
          handleNextSlide={handleNextSlide}
          setFeaturedShowApplicationType={setFeaturedShowApplicationType}
          setFeaturedShowTargetInEuros={setFeaturedShowTargetInEuros}
          showId={showId}
        />,
        <FeaturedShowConcept
          key="step2"
          featuredShowApplicationType={featuredShowApplicationType}
          featuredShowTargetInEuros={featuredShowTargetInEuros}
          handleNextSlide={handleNextSlide}
          showCategorySlug={showCategorySlug}
          showId={showId}
          showParentCategoryGlobalId={showGlobalParentCategoryId}
        />,
        <ShowEventApplicationSuccess key="step3" handleCloseModal={handleClose} />
      )
    } else if (showEventApplicationType?.type === 'commercialEvent') {
      slides.push(
        <CommercialEventCriteria
          key="step1"
          commercialEventApplicationCriteria={commercialEventApplicationCriteria}
          commercialEventBanner={showEventApplicationType.banner}
          commercialEventDescription={showEventApplicationType.description}
          commercialEventEndsAt={showEventApplicationType.endAt}
          commercialEventId={showEventApplicationType.id}
          commercialEventName={showEventApplicationType.name}
          commercialEventStartsAt={showEventApplicationType.startAt}
          handleNextSlide={handleNextSlide}
          showId={showId}
        />,
        <ShowEventApplicationSuccess key="step3" handleCloseModal={handleClose} />
      )
    }

    return slides
  }, [
    showEventApplicationType,
    commercialEventApplications,
    featuredShowApplicationStatus,
    showId,
    handleSelectType,
    handleNextSlide,
    featuredShowTargetInEuros,
    showCategorySlug,
    showGlobalParentCategoryId,
    handleClose,
    commercialEventApplicationCriteria,
  ])

  return (
    <Dialog
      className="show-event-application custom-dialog"
      isOpen={isOpen}
      title={<ShowEventApplicationHeader handlePreviousSlide={handlePreviousSlide} selectedSlide={selectedSlide} />}
      modal
      onClose={handleClose}
    >
      <div className="dialog-scrollable-content">
        <Carousel ref={carouselRef} selectedSlide={selectedSlide}>
          {renderSlides()}
        </Carousel>
      </div>
    </Dialog>
  )
}

export default ShowEventApplication
